@font-face {
  font-family: 'helveticaneue';
  src: url('/assets/fonts/helveticaneue.ttf')  format('truetype'), /* Safari, Android, iOS */
}

html, body { width:100%; min-height:100%; };
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'helveticaneue', Tahoma, Arial;
}

* {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Helvetica", 'helveticaneue', Tahoma, Arial;
  outline: none;
}

#root { background: rgba(0,0,0,.02); float: left; width: 100%; min-height: 100% }

.ui.table:last-child { margin-bottom: 30px; }
.ui.form.block {width:100%}
.react-datepicker-popper { width: 285px; z-index: 3!important; }
.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
}
.react-datepicker__input-container > input {padding-left: 35px!important;}

.ReactPasswordStrength { border:0!important; }
.ReactPasswordStrength > input { width: 100%; }
.ReactPasswordStrength-strength-desc { display: flex; justify-content: flex-end; }

/* login */
img[alt="logo"] {
  width: 300px;
  padding: 0 0 50px 0;
}

.popup { z-index: 9999!important; }
.sequenceInput .ui.input input { margin-bottom: 15px; }
.input-disabled input { pointer-events: visiblestroke; border:0!important; padding-left:0!important; opacity: .85; }
.input-disabled input:focus { outline: none; }
.input-disabled input:hover { cursor: not-allowed; }
.input-disabled > label { opacity: .7; }
.search-component > .input { width: 100%; margin-bottom: 15px; }
.search-component > .input i.search.icon { display: none;}
.results.transition.visible { margin-top: -10px; }
.rc-anchor-light { width: 99%!important; }
.till-font, .till-font > input {
  font-family: "Helvetica, Arial",sans-serif!important;
  font-size: 16px!important;
  font-weight: 300!important;
}
.till-font > tr > th { font-weight: lighter!important; }
button.till-font {
  background: #f8ca4a!important;
  color:black!important;
}
.ui.disabled.input, .disabled.field > label {opacity: 1;}
.disabled.field { opacity: .75!important; }
